<template>
  <div class="save-code">
    <div class="title-content">
      <div class="orgname1"><span class="span1" style="color:#4581F8FF">{{house.communityName}}</span><span class="span2" style="color:#4581F8FF">{{house.subareaName}}{{house.fullName}}</span></div>
     <div class="user" v-if="ownerList[0]">
      <span>房主:&nbsp;<span class="phone">{{ownerList[0].name||''}}&nbsp;{{ownerList[0].mobile||''}}</span></span> <img src="@/assets/img/mobile.png" alt="" class="tel" @click.stop="getRealMobile(ownerList[0].id)"/>
    </div>
      <div class="user" v-else>
        <span>暂无房主信息</span>
      </div>
    </div>
    <div class="user-info">
      <div class="user-info-title">{{ managerNum }}员信息</div>
      <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
        <div class="user-info-item" v-for="item in userInfoList">
          <img :src="item.avatar" alt="">
          <div>
            <div class="post">{{ item.workName }}</div>
            <div class="name">{{ item.name ||''}}</div>
            <div class="mobile">{{ getMobile(item.mobile) }}</div>
          </div>
        </div>

      </div>
    </div>
     <div class="myHouse">
     <div class="house">房屋信息</div>
       <div class="content" v-for="item in houseList" :key="item.id">
         <div class="orgname"><span class="span1">{{item.subareaName}}-{{item.fullName}}</span><span class="span2"><span class="span11" v-if="(item.useType==2||item.useType==3)&&item.residentsLimit&&item.resCount>item.residentsLimit">群租超限</span><span class="span22" v-if="item.useTypeStr">{{item.useTypeStr}}</span></span></div>
         <van-divider />
         <div class="user"  v-if="item.houseOwnerList[0]"  >
          <span class="phone">{{item.houseOwnerList[0].name||''}}&nbsp;{{item.houseOwnerList[0].mobile||''}}</span>
           <van-icon name="arrow" color="#666" class="bottom-icons" :style="{'top':'45%'}" @click.stop="infos(item.id,item.migrantNum)"/>
         </div>
         <div class="user"  v-else>
           <span class="phone">暂无人员信息</span>
           <van-icon name="arrow" color="#666" class="bottom-icons" :style="{'top':'45%'}" @click.stop="infos(item.id,item.migrantNum)"/>
         </div>
         <div class="user1 user">
           <span >居住：&nbsp;
             <span class="phone"  v-if="(item.useType==2||item.useType==3)&&item.residentsLimit" :style="{color:item.resCount>item.residentsLimit?'red':''}">{{item.resCount}}/{{item.residentsLimit}}（人）</span>
             <span class="phone"  v-else>{{item.resCount}}（人）</span>
           </span>
         </div>
       </div>

     </div>
    <div class="question"><span class="span1">待处理问题</span><van-button plain type="info"  @click="goProblem()">上报问题</van-button></div>
    <div class="House1" v-for=" item in problemList " :key="item.id">
      <div class="top-house">
        <span class="span1">{{item.problemTypeStr}}</span><span class="span2">未处理</span>
      </div>
      <van-divider />
      <div class="con-house">
      <div class="imgs">
        <van-image   alt="" fit="contain"  class="imgs" :src="item.urlOne||require('@/assets/img/default.png')" >
          <template v-slot:error>加载失败</template>
        </van-image>

      </div>
      <div class="left">
        <span class="title1">{{item.problemTypeStr}}</span>
        <span class="text">{{item.problemDes}}</span>
      </div>
        <van-icon  name="arrow" color="#666" class="bottom-icon"   @click="goProblemInfo(item)" />
      </div>
      <van-divider />
      <div class="bar-house">
        <span class="span1">{{item.createUserName}}&nbsp;{{item.createTime}}</span><van-button type="info"  v-if="finished" @click="finishProblem(item)">处理</van-button>
      </div>
    </div>
    <div class="notice-info">
      <div class="notice-title">公告<van-icon name="arrow" color="#D1D1D6" @click="goNotice()" /></div>
      <div class="notice-content" v-for="item in noticeList" @click="getInfo(item.id)">
        {{item.title}}
      </div>
    </div>
    <div class="notice-info">
      <div class="notice-title">平安宣传<van-icon name="arrow" color="#D1D1D6" @click="goNoticeSafe()" /></div>
      <div class="notice-content" v-for="item in noticeListSafe" @click="getInfo(item.id)">
        {{ item.title }}
      </div>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import { getImageStream } from '@/utils/index'
import uploadFile from '@/components/upload/uploadFile'
import { ImagePreview } from 'vant';
import {getRealMobile} from "@/utils/common";
export default {
  data() {
    return {
      finished:false,
      openid:'',
      unionid:'',
      mobile:'',
      noticeListSafe: '',
      houseName: '',
      houseId: 0,
      managerNum: '',
      orgId: '',
      param:'',
      noticeType:'',
      subareaId:'',
      noticeList: [],
      userInfoList: [],
      buildingId:'',
      url:'https://sgg.hzxh.gov.cn/police-api',
      type: 1, //1是房屋id,2是楼幢id
      id: '',
      userId:'',
      house:{},//当前房屋
      houseList:{},//房屋列表
      ownerList:{},//房主列表
      problemList:{},//未处理问题
      problemAppInfo:{},
      useTypeList:[],
    }
  },
  components: {
    uploadFile
  },
  created() {
    this.param=this.$route.query.params|| Vue.prototype.$qrcodeOptions
    this.userId=this.$route.query.userId||Vue.prototype.$userId

    if (this.$route.query.domain && this.$route.query.domain != 'null'&&!this.$route.query.p&&!this.$route.query.u) this.$http.changeUrl(this.$route.query.domain, true)
    this.getUsedict()
    this.getScanCodeParams()
  },

  methods: {
    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_bxjl,sjgl_wtjl,sjgl_xcjl',
          orgId: this.orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {

            if(item.appCode === 'sjgl_wtjl') {
              // this.isProblemOpen = true
              this.problemAppInfo = item
            }

          })
          if(data.list && data.list.length) {
            let appId = data.list[0].appId
            // getAppToken(appId)
            // this.$http({
            //   url: this.$http.adornUrl('/wxapp/application/appToken'),
            //   method: 'get',
            //   params: this.$http.adornParams({
            //     appId,
            //   })
            // }).then(({data}) => {
            //   if(data && data.code === 0) {
            //     this.$httpApp({
            //       url: this.$httpApp.adornUrl('/auth/getLoginUser'),
            //       method: 'GET',
            //       params: this.$httpApp.adornParams({
            //         appId,
            //         appToken: data.appToken
            //       })
            //     }).then(({data}) => {
            //       if(data && data.code === 0) {
            //         sessionStorage.setItem('appToken', data.appToken)
            //       }
            //     })
            //   }
            // })
          }
        }
      })
    },
    infos(id,migrantNum){
      console.log(222)
        this.$store.commit("setHouseId", id);
        this.$store.commit("setMigrantNum", migrantNum)
        if (this.type != 3) {
          this.$router.push({
            path: "/family-info",
            query: { id, userId:this.userId,orgId:this.orgId},
          })
        } else {
          this.$router.push({
            path: "/isolation-info",
            query: { id },
          })
        }

    },
    getRealMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    getInfo(id) {
      this.$store.commit('setNoticeId', id)
      this.$router.push('/notice-info')
    },
    goNoticeSafe() {
      let tmp1=this.orgId
      let tmp2=this.noticeType
      this.$router.push({path:'/notice',query:{orgId:tmp1,noticeType:tmp2}})
    },
    goNotice() {
      let tmp1=this.orgId
      let tmp2=1
      this.$router.push({path:'/notice',query:{orgId:tmp1,isOpen:tmp2}})
    },
    getMobile(value) {
      let result = [];
      for (let i = 0; i < value.length; i++) {
        if (i == 3 || i == 7) {
          result.push(" " + value.charAt(i));
        } else {
          result.push(value.charAt(i));
        }
      }
      return result.join('')
    },
    numChange(i) {
      let arry = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十"];
      if (i <= 10) {
        return arry[i];
      }
      if (i % 10 == 0 && i < 100) {
        return arry[Math.floor(i / 10)] + "十";
      }
      if (i > 10 && i < 20) {
        return "十" + this.numChange(i - 10 * Math.floor(i / 10));
      }
      if (i > 20 && i < 100 && i % 10 != 0) {
        return arry[Math.floor(i / 10)] + "十" + numChange(i - 10 * Math.floor(i / 10));
      }
    },
    getScanCodeParams() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/scanCode/getScanCodeParams'),
        method: 'get',
        params: this.$http.adornParams({
          id: this.param
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let houseNameList = data.data.houseName.split('-')
          this.houseId = data.data.houseId
          this.id = this.houseId
          this.buildingId = data.data.buildingId
          if (this.buildingId){
            this.type = 2
            this.id = this.buildingId
          }
          this.orgId = data.data.orgId
          this.houseName = houseNameList.splice(2, houseNameList.length - 1).join('-')
          this.getAppInfo()
          this.getMessage()
          this.getManager()
          this.getNoticeType()
          this.getNotice()
          if (this.orgId) {
            this.getStreetId(this.orgId)
          }
        }
      })
    },
    finishProblem(item){
      this.$router.push({path: '/problem-handling', query: {ids: item.id}})
    },
    //获取居住类型
    getUsedict(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual'),
        method: 'GET',
        params: this.$http.adornParams({
          code: 'useType'
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.useTypeList = data.virtualDicts
          this.useTypeList.forEach((item)=>{
            item.number=0
          })

        }
      })
    },
    //获取扫码用户信息
    getMessage(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/statistics/userHouse'),
        method: 'get',
        params: this.$http.adornParams({
          userId:this.userId,
          houseId:this.houseId
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
         this.house=data.userHouse.house
          this.houseList=data.userHouse.houseList
          this.houseList.forEach(item=>{
             this.useTypeList.find((value) => {
               if(value.value==item.useType){
                 item.useTypeStr=value.label
               }
             })

            if(!item.resCount){
              item.resCount=0
            }

          })
          this.ownerList=data.userHouse.ownerList
          this.ownerList.forEach(item=>{
            if(item.id==this.userId){

              this.finished=true
            }
          })
          this.problemList=data.userHouse.problemList
          if( this.problemList.length>0) {
            this.problemList.forEach(item=>{
              if (item.imgUrl) {
                item.urlOne = getImageStream(item.imgUrl.split(',')[0])
              }
            })


          }

          this.$forceUpdate();
        }
      })
    },
    //跳转问题
    goProblem() {
      let url = this.problemAppInfo.pageUrlH5.split('=')[0] + '=problem-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          houseId:this.houseId,
          SM:'saoma',
          orgId: this.orgId ,
          userId: this.userId,
          appId: this.problemAppInfo.appId,
          appCode: this.problemAppInfo.appCode,
          appName: this.problemAppInfo.appName,
          url: url
        }
      })
    },
    goProblemInfo(item){
      let url = this.problemAppInfo.pageUrlH5.split('=')[0] + '=problem-info'
      this.$router.push({
        path: '/appSSO',
        query: {
          buildingId:this.buildingId,
          SM:'saoma',
          orgId: this.orgId ,
          problemId: item.id + '',
          appId: this.problemAppInfo.appId,
          appCode: this.problemAppInfo.appCode,
          appName: this.problemAppInfo.appName,
          url: url
        }
      })
    },
    getNotice() {
      this.$httpApp({
        url: this.url +`/wxapp/commonApp/notice/listWithoutToken`,
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.orgId,
          orgUserId: 0,
          isOpen: 1
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeList=data.page.list
        }
      })
    },
    getNoticeType() {
      this.$http({
        url: this.$http.adornUrl(`/sys/dict/listDictByCode`),
        method: 'get',
        params: this.$http.adornParams({
          code: 'noticeType',
          orgId: this.orgId
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          data.dicts.forEach(item => {
            if (item.label === '平安宣传' && item.parameters.type === 'safetyCode') {
              this.noticeType = item.value
            }
          })
          this.getNoticeSafe()
        }
      })
    },
    getNoticeSafe() {
      this.$httpApp({
        url: this.$httpApp.adornUrl( `/wxapp/commonApp/notice/listWithoutToken`),
        method: 'post',
        params: this.$httpApp.adornParams({
          page: 1,
          limit: 2,
          logInOrgId: this.orgId,
          orgUserId: 0,
          noticeType: this.noticeType
        })
      }).then(({ data }) => {
        if (data && data.code == 0) {
          this.noticeListSafe=data.page.list
        }
      })
    },
    getManager() {
      this.$httpApp({
        url: this.$httpApp.adornUrl( `/building/manager/info/getManager/${this.id}`),
        method: 'get',
        params: this.$httpApp.adornParams({
          type: this.type
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          let tmp = data.managers
          data.managers.map((item, index) => {
            tmp[index].avatar = getImageStream(item.avatar)
          })
          this.userInfoList = tmp
          this.managerNum = this.numChange(data.managers.length)
        }
      })
    },
    // 根据社区id获取街道id
    getStreetId(id) {
      this.$http({
        url: this.$http.adornUrl(`/sys/org/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          let street = data.sysOrg.path.split(',')
          let newStreet = street.splice(0, street.length - 1)
          this.streetPath = newStreet.slice(1).toString()
        }
      })
    },
    // 根据楼幢id获取小区楼幢
    getBuildingId(id) {
      this.$http({
        url: this.$http.adornUrl(`/building/info/noLogin/info/${id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0 && data) {
          this.subareaId = data.buildingInfo.subarea
          this.$route.query.type = 'zuke'
          this.$route.query.subareaId = this.subareaId
          this.$route.query.buildingId = this.buildingId
          this.$router.push({ path: '/newRegister', query: this.$route.query})
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
.save-code {
  font-family: 'PingFangSC-Regular', serif;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  background-image: url("~@/assets/img/save/saveCode-bg.png");
  padding-bottom: 24px;

  .title-content {

    //background-color: #FFFFFF;
    padding: 24px 0px  0 0px;
    //margin: 54px auto 0;
    width: 750px;
    text-align: center;
    position: relative;
    line-height: 48px;
    .van-divider{
      margin: 26px 22px;
    }
    .orgname1{
      width: 690px;
      height: 136px;
      margin: 44px auto 0;
      padding: 0 30px 0 30px;
      background-image: url("~@/assets/img/save/saveCode-cube.png");
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .span1{
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
        text-align: left;
        font-style: normal;
      }
      .span2{
        font-weight: 600;
        font-size: 30px;
        color: #666666;
        line-height: 42px;
        text-align: left;
        font-style: normal;
      }

    }
    .user1{
      font-family: 'PingFangSC-Regular', serif;
      font-weight: 400;
      font-size: 24px;
      color: #666666;
      line-height: 34px;
      text-align: center;
      font-style: normal;
    }
    .user{
      display: flex;
      justify-content: space-between;
      margin-top:24px;
      padding-left: 30px;
      height:70px;
      background-color: #FFFFFF;
      align-items:center;
      padding-right: 30px;
      span{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 400;
        font-size: 28px;
        color: #666666;
        line-height: 70px;
        text-align: center;
        font-style: normal;
        .phone{
          font-family: 'PingFang Bold', serif;
          font-weight: 500;
          color: #333333;
        }
      }
      img{
        width: 40px;
        height: 40px;
      }
    }

    .title {
      font-size: 34px;
      color: #333;
    }
  }

  .house-content {
    width: 690px;
    height: 136px;
    margin: 44px auto 0;
    background-image: url("~@/assets/img/save/saveCode-cube.png");
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;

    .house-name {
      font-size: 32px;
      color: #4581F8;
    }

    .btn {
      display: inline-block;
      margin-right: 24px;
      width: 136px;
      line-height: 52px;
      text-align: center;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid #4581F8;
      font-size: 24px;
      color: #4581F8;
    }

    .btn1 {
      background: linear-gradient(180deg, #9ABCFF 0%, #4581F8 100%);
      color: #fff;
    }
  }

  .content-title {
    height: 88px;
    color: #333;
    font-size: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-info {
    width: 750px;
    padding: 0 30px;
    background: #fff;
    margin-top: 24px;
    border-radius: 0px 0px 20px 20px;

    .user-info-title {
      height: 88px;
      color: #333;
      font-size: 36px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .user-info-item {
      width: 336px;
      height: 170px;
      background: #F8F9FA;
      display: flex;
      padding: 26px 18px;
      margin-bottom: 24px;

      img {
        width: 120px;
        height: 120px;
        margin-right: 12px;
      }

      .post {
        font-size: 26px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .name {
        font-size: 26px;
        color: #333;
        line-height: 36px;
        margin-top: 4px;
      }

      .mobile {
        font-size: 24px;
        color: #666;
        line-height: 34px;
        margin-top: 4px;
      }
    }
  }

  .notice-info {
    width: 690px;
    margin: 24px 30px 0;
    background: #fff;
    border-radius: 20px;
    padding-bottom: 8px;

    .notice-title {
      height: 88px;
      color: #333;
      font-size: 36px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 24px;
    }

    .notice-content {
      padding: 0 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 16px;
      font-size: 28px;
      color: #666;
      line-height: 40px;
    }
    .notice-img
    #default{
      display:flex;
      flex-direction:column;
      justify-content: space-around;
      align-items: center;
    }
    .img{
      background: #F8F9FA;
      width: 642px;
      height: 370px;
      margin: 0 24px;
      margin-bottom: 16px;
      border-radius: 10px;
      border-width: 0;
      .in-img{
        max-width:"180px";
        max-height:"133px";
      }
    }
  }
}
.myHouse{
  .content{
    position: relative;
    margin-bottom: 15px;
    .van-divider{
      margin: 20px 0 20px 0;
    }
    padding: 26px 30px 24px 30px;
    background-color: white;
    .orgname{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
      .span1{
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
        text-align: left;
        font-style: normal;
      }
      .span2{
        display: flex;
        flex-direction: row;
        gap:24px;
        height: 52px;
        .span11{
          padding:8px 20px 8px 20px;
          font-weight: 400;
          font-size: 24px;
          color: #E9564B;
          line-height: 34px;
          text-align: left;
          font-style: normal;
          background: rgba(233,86,75,0.1);
          border-radius: 8px;
        }
        .span22{
          padding:8px 20px 8px 20px;
          font-weight: 400;
          font-size: 24px;
          color: #4E7CF5;
          line-height: 34px;
          text-align: right;
          font-style: normal;
          background: rgba(78,124,245,0.12);
          border-radius: 8px;
        }
      }
    }
    .user{
      display: flex;
      justify-content: space-between;
      margin-top:24px;
      .bottom-icons{
        font-size: 38px;
      }
      span{
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 400;
        font-size: 28px;
        color: #666666;
        line-height: 40px;
        text-align: center;
        font-style: normal;
        .phone{
          font-family: 'PingFang Bold', serif;
          font-weight: 500;
          color: #333333;
        }
      }
      img{
        width: 40px;
        height: 40px;
      }
    }
  }
  .house{
    margin-top: 24px;
    padding: 26px 0 0 30px;
    height: 98px;
    background-color: #FFFFFF;
    font-family: 'PingFangSC-Regular', serif;
    font-weight: 600;
    font-size: 32px;
    color: #333333;
    line-height: 44px;
    text-align: left;
    font-style: normal;
  }

}
.House1 {
  background-color: #FFFFFF;
  padding-top:10px ;
  border-bottom: 1px solid rgba(220,220,220,0.6);
  position: relative;
  .con-house{
    height:180px;
    width: 750px;
    display: flex;
    flex-direction: row;
    gap:18px;
    .van-icon{
      font-size: 38px;
      right:20px;
      top:160px;
      position: absolute;;
      height: 48px;
      opacity: 0.66;
    }
    .imgs {


      width: 220px;
      height: 170px;
    }
    .left{
      display: flex;
      flex-direction: column;
      gap:4px;
      .title1{
        width: 300px;
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 600;
        font-size: 32px;
        color: #333333;
        line-height: 44px;
        text-align: left;
        font-style: normal;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      .text{

        width: 400px;
        font-family: 'PingFangSC-Regular', serif;
        font-weight: 400;
        font-size: 30px;
        color: #999999;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        span{
          color: #333333;
        }
      }
    }
  }
  .van-divider{
    margin: 16px 22px;
  }
.bar-house{
  padding: 6px 30px 24px 30px;
  width: 750px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  .van-button{
    font-size: 30px;
    width: 152px;
    height: 60px;
    background: #4581F8;
    border-radius: 8px;
  }
  .span1{
    font-family: 'PingFangSC-Regular', serif;
    font-weight: 400;
    font-size: 24px;
    color: #666666;
    line-height: 60px;
    text-align: left;
    font-style: normal;
  }

}
  .top-house{
    padding: 16px 30px 24px 30px;
    width: 750px;
    height: 74px;
    display: flex;
    justify-content: space-between;
    .span1{
      font-weight: 600;
      font-size: 30px;
      color: #333333;
      line-height: 60px;
      text-align: left;
      font-style: normal;
    }
    .span2{
      color:#E9564B;
      font-size: 30px;
      padding:8px 20px 8px 20px;
      height: 52px;
      background: rgba(233,86,75,0.1);
      border-radius: 8px;
    }
  }

}
.question{
  background-color: #FFFFFF;
  padding: 16px 30px 24px 30px;
  width: 750px;
  display: flex;
  justify-content: space-between;
  .van-button{
    font-size: 24px;
    width: 162px;
    height: 60px;
  }
  .span1{
    font-family: 'PingFangSC-Regular', serif;
    height: 60px;
    font-weight: 600;
    font-size: 32px;
    color: #333333;
    line-height: 60px;
    text-align: left;
    font-style: normal;
  }
}
</style>
